<template>
  <v-dialog :value="true" width="400" persistent>
    <v-card>
      <v-card-title class="cor-marrom">
        {{ $t('trocar_senha.trocar_senha') }}
      </v-card-title>
      <v-card-text>
        <form>
          <v-text-field
            v-model="senhaAtual"
            :error-messages="senhaAtualValidate"
            :counter="20"
            :label="$t('trocar_senha.senha_atual')"
            required
            type="password"
            @input="$v.senhaAtual.$touch()"
            @blur="$v.senhaAtual.$touch()"
          />
          <v-text-field
            v-model="senhaNova"
            :error-messages="senhaNovaValidate"
            :counter="20"
            :label="$t('trocar_senha.nova_senha')"
            required
            type="password"
            @input="$v.senhaNova.$touch()"
            @blur="$v.senhaNova.$touch()"
          />
          <v-text-field
            v-model="senhaConfirma"
            :error-messages="senhaConfirmaValidate"
            :counter="20"
            :label="$t('trocar_senha.confirma_nova_senha')"
            required
            type="password"
            @input="$v.senhaConfirma.$touch()"
            @blur="$v.senhaConfirma.$touch()"
          />
          <v-checkbox
            v-model="checkboxTermo"
            :error-messages="checkboxTermoValidate"
            :label="$t('trocar_senha.aceito_termos')"
            required
            @change="$v.checkboxTermo.$touch()"
            @blur="$v.checkboxTermo.$touch()"
          />

          <a :href="termsOfUseUrl" target="_blank">
            ({{ $t('trocar_senha.acesse_termos') }})
          </a>
          <br />
          <v-btn class="mr-4 mt-4 cor-verde" @click="submit">
            {{ $t('salvar') }}
          </v-btn>
          <v-btn class="mr-4 mt-4 cor-verde" @click="clear">
            {{ $t('limpar') }}
          </v-btn>
          <v-btn class="mr-4 mt-4 cor-verde" v-if="!firstLogin" @click="close">
            {{ $t('sair') }}
          </v-btn>
        </form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, minLength } from 'vuelidate/lib/validators'
import { mapActions, mapGetters } from 'vuex'
import { getTermsOfUse } from '@/utils/termsOfUse'

const senhaMinChars = 8

export default {
  name: 'ModalSenha',

  props: {
    firstLogin: {
      default: false,
      type: Boolean,
    },
  },

  mixins: [validationMixin],

  validations: {
    senhaAtual: {
      required,
      minLength: minLength(senhaMinChars),
    },
    senhaNova: {
      required,
      minLength: minLength(senhaMinChars),
    },
    senhaConfirma: {
      required,
      minLength: minLength(senhaMinChars),
    },
    checkboxTermo: {
      checked(val) {
        return val
      },
    },
  },

  data: () => ({
    senhaAtual: '',
    senhaNova: '',
    senhaConfirma: '',
    checkboxTermo: false,
  }),

  computed: {
    ...mapGetters('user', ['currentUser', 'getCountry']),
    termsOfUseUrl() {
      return 'doc/' + getTermsOfUse(this.getCountry)
    },
    senhaAtualValidate() {
      const errors = []

      if (this.$v.senhaAtual.$dirty) {
        if (!this.$v.senhaAtual.required) {
          errors.push(this.$t('trocar_senha.valida_senha_vazio'))
          return errors
        }

        if (!this.$v.senhaAtual.minLength) {
          errors.push(this.$t('trocar_senha.senha_min', [senhaMinChars]))
          return errors
        }
      }

      return errors
    },
    senhaNovaValidate() {
      const errors = []

      if (this.$v.senhaNova.$dirty) {
        if (!this.$v.senhaNova.required) {
          errors.push(this.$t('trocar_senha.valida_senha_nova_vazio'))
          return errors
        }

        if (!this.$v.senhaNova.minLength) {
          errors.push(this.$t('trocar_senha.senha_min', [senhaMinChars]))
          return errors
        }
      }

      return errors
    },
    senhaConfirmaValidate() {
      const errors = []

      if (this.$v.senhaConfirma.$dirty) {
        if (!this.$v.senhaConfirma.required) {
          errors.push(this.$t('trocar_senha.valida_senha_confirmacao_vazio'))
          return errors
        }

        if (!this.$v.senhaConfirma.minLength) {
          errors.push(this.$t('trocar_senha.senha_min', [senhaMinChars]))
          return errors
        }
      }

      return errors
    },
    checkboxTermoValidate() {
      const errors = []

      if (this.$v.checkboxTermo.$dirty && !this.$v.checkboxTermo.checked) {
        errors.push(this.$t('trocar_senha.confirme_termos'))
      }
      return errors
    },
  },
  methods: {
    ...mapActions('user', ['fetchUserData']),
    async submit() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        try {
          const response = await this.$api.auth.changePassword(
            this.senhaAtual,
            this.senhaNova,
            this.senhaConfirma,
            this.currentUser.email
          )
          if (response.status === 200) {
            await this.$api.auth.deleteTemporaryPassword(this.currentUser.id)
          }
          await this.fetchUserData()
          this.close()
        } catch (error) {
          console.error(error)
        }
      }
    },
    clear() {
      this.$v.$reset()
      this.senhaAtual = ''
      this.senhaNova = ''
      this.senhaConfirma = ''
      this.checkboxTermo = false
    },
    close() {
      this.clear()
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss" scoped>
.cor-marrom {
  background-color: #efbf41 !important;
  border: none !important;
  box-shadow: none !important;
}
.cor-verde {
  background-color: #94c858 !important;
}
v-text-field {
  height: 20px;
}
</style>
